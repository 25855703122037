import { LanguageProvider } from './src/context/LanguageContext'; // LanguageProvider'ı import edin
import React from 'react';





export const onRouteUpdate = ({ location, prevLocation }) => {
    if (typeof window !== 'undefined' && window._hsq) {
        window._hsq.push(['trackEvent', 'Page View', { page: location.pathname }]);
    }
};
export const wrapRootElement = ({ element }) => {
    return <LanguageProvider>{element}</LanguageProvider>;
};
