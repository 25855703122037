exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anasayfa-js": () => import("./../../../src/pages/anasayfa.js" /* webpackChunkName: "component---src-pages-anasayfa-js" */),
  "component---src-pages-blog-icerigi-js": () => import("./../../../src/pages/blog-icerigi.js" /* webpackChunkName: "component---src-pages-blog-icerigi-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-digital-pr-js": () => import("./../../../src/pages/digital-pr.js" /* webpackChunkName: "component---src-pages-digital-pr-js" */),
  "component---src-pages-dizin-ad-deneme-js": () => import("./../../../src/pages/dizin-ad/deneme.js" /* webpackChunkName: "component---src-pages-dizin-ad-deneme-js" */),
  "component---src-pages-donusum-optimizasyonu-js": () => import("./../../../src/pages/donusum-optimizasyonu.js" /* webpackChunkName: "component---src-pages-donusum-optimizasyonu-js" */),
  "component---src-pages-ekitap-indir-js": () => import("./../../../src/pages/ekitap-indir.js" /* webpackChunkName: "component---src-pages-ekitap-indir-js" */),
  "component---src-pages-gizlilik-sozlesmesi-js": () => import("./../../../src/pages/gizlilik-sozlesmesi.js" /* webpackChunkName: "component---src-pages-gizlilik-sozlesmesi-js" */),
  "component---src-pages-growth-hacking-js": () => import("./../../../src/pages/growth-hacking.js" /* webpackChunkName: "component---src-pages-growth-hacking-js" */),
  "component---src-pages-hakkimizda-js": () => import("./../../../src/pages/hakkimizda.js" /* webpackChunkName: "component---src-pages-hakkimizda-js" */),
  "component---src-pages-iletisim-formu-js": () => import("./../../../src/pages/iletisim-formu.js" /* webpackChunkName: "component---src-pages-iletisim-formu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pazarlama-analitigi-js": () => import("./../../../src/pages/pazarlama-analitigi.js" /* webpackChunkName: "component---src-pages-pazarlama-analitigi-js" */),
  "component---src-pages-pazarlama-icin-oyunlastirma-js": () => import("./../../../src/pages/pazarlama-icin-oyunlastirma.js" /* webpackChunkName: "component---src-pages-pazarlama-icin-oyunlastirma-js" */),
  "component---src-pages-pazarlama-otomasyonu-js": () => import("./../../../src/pages/pazarlama-otomasyonu.js" /* webpackChunkName: "component---src-pages-pazarlama-otomasyonu-js" */),
  "component---src-pages-performans-pazarlama-js": () => import("./../../../src/pages/performans-pazarlama.js" /* webpackChunkName: "component---src-pages-performans-pazarlama-js" */),
  "component---src-pages-programatik-reklamcilik-js": () => import("./../../../src/pages/programatik-reklamcilik.js" /* webpackChunkName: "component---src-pages-programatik-reklamcilik-js" */),
  "component---src-pages-sartlar-ve-kosullar-js": () => import("./../../../src/pages/sartlar-ve-kosullar.js" /* webpackChunkName: "component---src-pages-sartlar-ve-kosullar-js" */),
  "component---src-pages-seo-js": () => import("./../../../src/pages/seo.js" /* webpackChunkName: "component---src-pages-seo-js" */),
  "component---src-pages-sosyal-medya-yonetimi-js": () => import("./../../../src/pages/sosyal-medya-yonetimi.js" /* webpackChunkName: "component---src-pages-sosyal-medya-yonetimi-js" */),
  "component---src-pages-stratejiler-js": () => import("./../../../src/pages/stratejiler.js" /* webpackChunkName: "component---src-pages-stratejiler-js" */),
  "component---src-pages-vaka-calismalari-js": () => import("./../../../src/pages/vaka-calismalari.js" /* webpackChunkName: "component---src-pages-vaka-calismalari-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/blog-post-template.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-case-study-template-js": () => import("./../../../src/templates/case-study-template.js" /* webpackChunkName: "component---src-templates-case-study-template-js" */),
  "component---src-templates-ebook-download-js": () => import("./../../../src/templates/ebook-download.js" /* webpackChunkName: "component---src-templates-ebook-download-js" */)
}

