import React, { createContext, useState, useEffect } from 'react';

// Context oluşturma
export const LanguageContext = createContext();

// Provider bileşeni
export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('tr'); // Varsayılan dil

    // Dil bilgisini localStorage'da saklama
    useEffect(() => {
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage) {
            setLanguage(savedLanguage);
        }
    }, []);

    const changeLanguage = (lang) => {
        setLanguage(lang);
        localStorage.setItem('language', lang);
    };

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
